import Link from '@tiptap/extension-link'

const CustomLink = Link.extend({
	addOptions() {
		return {
			...this.parent?.(),
			HTMLAttributes: {
				target: '_blank',
				rel: '',
				'aria-label': 'Opens in a new window',
			},
		}
	},
	addAttributes() {
		return {
			...this.parent?.(),
			'aria-label': {
				default: '_blank',
			},
		}
	},
})

export default CustomLink
