<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" for="title" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative" :class="{ 'opacity-50 disabled': disabled }">
			<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>

			<div class="border border-gray-300 rounded-b-md">
				<div class="sticky top-0 z-10 bg-gray-200">
					<div class="border-b flex flex-wrap">
						<button
							v-show="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="rawHTML = true"
						>
							View HTML
						</button>

						<button
							v-show="rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="updateEditor"
						>
							Save HTML to Editor
						</button>

						<!-- <button
							v-show="rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="updateEditor"
						>
							Update Editor
						</button> -->

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('bold'),
							}"
							@click="
								editor
									.chain()
									.toggleBold()
									.focus()
									.run()
							"
						>
							<font-awesome-icon icon="bold" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('italic'),
							}"
							@click="
								editor
									.chain()
									.focus()
									.toggleItalic()
									.run()
							"
						>
							<font-awesome-icon icon="italic" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('underline'),
							}"
							@click="
								editor
									.chain()
									.focus()
									.toggleUnderline()
									.run()
							"
						>
							<font-awesome-icon icon="underline" />
						</button>

						<div
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
						>
							<Listbox v-model="fontSize" as="div">
								<div class="relative w-28">
									<ListboxButton
										class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									>
										<span class="block truncate">{{ fontSize.name }}</span>
										<span
											class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
										>
											<SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</ListboxButton>

									<transition
										leave-active-class="transition ease-in duration-100"
										leave-from-class="opacity-100"
										leave-to-class="opacity-0"
									>
										<ListboxOptions
											class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
										>
											<ListboxOption
												v-for="size in fontSizes"
												:key="size.size"
												v-slot="{ selected }"
												as="template"
												:value="size"
											>
												<li
													class="text-gray-900 hover:bg-gray-50 hover:cursor-pointer select-none relative py-2 pl-3 pr-9"
												>
													<span
														:class="[
															selected ? 'font-semibold' : 'font-normal',
															'block truncate',
														]"
													>
														{{ size.name }}
													</span>

													<span
														v-if="selected"
														class="text-sc-blue absolute inset-y-0 right-0 flex items-center pr-4"
													>
														<CheckIcon class="h-5 w-5" aria-hidden="true" />
													</span>
												</li>
											</ListboxOption>
										</ListboxOptions>
									</transition>
								</div>
							</Listbox>
						</div>

						<div
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
						>
							<Listbox v-model="fontColor" as="div">
								<div class="relative w-20">
									<ListboxButton
										class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									>
										<span class="block truncate h-5 w-8" :class="fontColorBg(fontColor)"></span>
										<span
											class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
										>
											<SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</ListboxButton>

									<transition
										leave-active-class="transition ease-in duration-100"
										leave-from-class="opacity-100"
										leave-to-class="opacity-0"
									>
										<ListboxOptions
											class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
										>
											<ListboxOption
												v-for="color in fontColors"
												:key="color.color"
												v-slot="{ selected }"
												as="template"
												:value="color"
											>
												<li
													class="text-gray-900 hover:bg-gray-50 hover:cursor-pointer select-none relative py-2 pl-3 pr-9"
												>
													<span
														class="block truncate h-5 w-8"
														:class="fontColorBg(color)"
													></span>

													<span
														v-if="selected"
														class="text-sc-blue absolute inset-y-0 right-0 flex items-center pr-4"
													>
														<CheckIcon class="h-5 w-5" aria-hidden="true" />
													</span>
												</li>
											</ListboxOption>
										</ListboxOptions>
									</transition>
								</div>
							</Listbox>
						</div>

						<!-- <button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="openSlide('MediaColorSelect')"
						>
							<font-awesome-icon icon="palette" />
						</button> -->

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-600 text-blue-50': editor.isActive('columnHeading'),
							}"
							@click="
								editor
									.chain()
									.focus()
									.toggleColumnHeading()
									.run()
							"
						>
							<font-awesome-icon icon="heading" />
						</button>

						<button
							v-if="!rawHTML && editor.isActive('paragraph')"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="
								editor
									.chain()
									.focus()
									.toggleIndent()
									.run()
							"
						>
							<font-awesome-icon icon="indent" />
						</button>

						<button
							v-if="!rawHTML && editor.isActive('indent')"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							@click="
								editor
									.chain()
									.focus()
									.toggleIndent()
									.run()
							"
						>
							<font-awesome-icon icon="outdent" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('orderedList'),
							}"
							@click="
								editor
									.chain()
									.focus()
									.toggleOrderedList()
									.run()
							"
						>
							<font-awesome-icon icon="list-ol" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('bulletList'),
							}"
							@click="
								editor
									.chain()
									.focus()
									.toggleBulletList()
									.run()
							"
						>
							<font-awesome-icon icon="list-ul" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('link'),
							}"
							@click="openSlide('MediaLinkSelect')"
						>
							<font-awesome-icon icon="link" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							title="Button"
							:class="{
								'bg-gray-700 text-blue-50': editor.isActive('buttonLink'),
							}"
							@click="openSlide('MediaLinkSelect', 'button')"
						>
							<!-- <font-awesome-icon icon="square" /> -->
							<IconButtonLink class="w-6 h-6" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-600 text-blue-50': editor.isActive('image'),
							}"
							@click="openSlide('MediaImageSelect')"
						>
							<font-awesome-icon icon="image" />
						</button>

						<button
							v-if="!rawHTML"
							class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
							:class="{
								'bg-gray-600 text-blue-50': editor.isActive('table'),
							}"
							@click="addTable"
						>
							<font-awesome-icon icon="table" />
						</button>
					</div>

					<div v-if="editor.isActive('image')" class="p-1 border-b flex flex-wrap gap-x-1">
						<div class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								W
							</span>
							<input
								v-model="selection.image.width"
								type="number"
								min="100"
								:max="selection.image.maxWidth"
								size="5"
								class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-xs border-gray-300"
								@input="updateImage('width')"
							/>
						</div>
						<div class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								H
							</span>
							<input
								v-model="selection.image.height"
								type="number"
								min="100"
								:max="selection.image.maxHeight"
								size="5"
								class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-xs border-gray-300"
								@input="updateImage('height')"
							/>
						</div>
						<div class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								Position
							</span>
							<select
								v-model="selection.image.position"
								class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-xs rounded-r-md"
							>
								<option value="float-none">Normal</option>
								<option value="float-right ml-5">Float Right</option>
								<option value="float-left mr-5">Float Left</option>
							</select>
						</div>
						<div class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								Radius
							</span>
							<select
								v-model="selection.image.radius"
								class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-xs rounded-r-md"
							>
								<option value="">None</option>
								<option value="rounded-sm">Small</option>
								<option value="rounded-md">Medium</option>
								<option value="rounded-lg">Large</option>
								<option value="rounded-xl">Extra Large</option>
								<option value="rounded-full">Full</option>
							</select>
						</div>
						<div class="flex gap-x-2">
							<button
								class="relative inline-flex items-center rounded-md px-2 py-1 h-8 border-r border-gray-300 bg-gray-500 text-white text-sm font-medium hover:bg-gray-600 focus:z-10 focus:outline-none"
								@click="updateImage(false)"
							>
								Update Image
							</button>
						</div>
					</div>

					<div v-if="editor.isActive('link')" class="p-1 border-b flex flex-wrap gap-x-2">
						<div v-if="selection.link" class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								URL
							</span>
							<input
								v-model="selection.link.href"
								type="url"
								size="39"
								class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-xs border-gray-300"
								readonly
							/>
						</div>
						<div class="flex gap-x-2">
							<button
								class="relative inline-flex items-center rounded-md px-2 py-1 h-8 border-r border-gray-300 bg-gray-500 text-white text-sm font-medium hover:bg-gray-600 focus:z-10 focus:outline-none"
								@click="editLink()"
							>
								<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
							</button>
							<button
								class="relative inline-flex items-center rounded-md px-2 py-1 h-8 border-r border-gray-300 bg-red-500 text-white text-sm font-medium hover:bg-red-600 focus:z-10 focus:outline-none"
								@click="
									editor
										.chain()
										.focus()
										.unsetLink()
										.run()
								"
							>
								<XIcon class="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>

					<div v-if="editor.isActive('buttonLink')" class="p-1 border-b flex flex-wrap gap-x-2">
						<div v-if="selection.buttonLink" class="h-8 max-w-sm flex rounded-md shadow-sm">
							<span
								class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"
							>
								URL
							</span>
							<input
								v-model="selection.buttonLink.href"
								type="url"
								size="39"
								class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-xs border-gray-300"
								readonly
							/>
						</div>
						<div class="flex gap-x-2">
							<button
								class="relative inline-flex items-center rounded-md px-2 py-1 h-8 border-r border-gray-300 bg-gray-500 text-white text-sm font-medium hover:bg-gray-600 focus:z-10 focus:outline-none"
								@click="editButtonLink()"
							>
								<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
							</button>
							<button
								class="relative inline-flex items-center rounded-md px-2 py-1 h-8 border-r border-gray-300 bg-red-500 text-white text-sm font-medium hover:bg-red-600 focus:z-10 focus:outline-none"
								@click="
									editor
										.chain()
										.focus()
										.unsetButtonLink()
										.run()
								"
							>
								<XIcon class="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>

					<div v-if="editor.isActive('table')" class="border-b flex flex-wrap gap-x-2">
						<div>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Add Column Before"
								:disabled="!editor.can().addColumnBefore()"
								@click="
									editor
										.chain()
										.focus()
										.addColumnBefore()
										.run()
								"
							>
								<IconTableColumnPlusBefore class="w-6 h-6" />
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Add Column After"
								:disabled="!editor.can().addColumnAfter()"
								@click="
									editor
										.chain()
										.focus()
										.addColumnAfter()
										.run()
								"
							>
								<IconTableColumnPlusAfter class="w-6 h-6" />
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Delete Column"
								:disabled="!editor.can().deleteColumn()"
								@click="
									editor
										.chain()
										.focus()
										.deleteColumn()
										.run()
								"
							>
								<IconTableColumnRemove class="w-6 h-6" />
							</button>
						</div>
						<div>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Add Row Before"
								:disabled="!editor.can().addRowBefore()"
								@click="
									editor
										.chain()
										.focus()
										.addRowBefore()
										.run()
								"
							>
								<IconTableRowPlusBefore class="w-6 h-6" />
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Add Row After"
								:disabled="!editor.can().addRowAfter()"
								@click="
									editor
										.chain()
										.focus()
										.addRowAfter()
										.run()
								"
							>
								<IconTableRowPlusAfter class="w-6 h-6" />
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Delete Row"
								:disabled="!editor.can().deleteRow()"
								@click="
									editor
										.chain()
										.focus()
										.deleteRow()
										.run()
								"
							>
								<IconTableRowRemove class="w-6 h-6" />
							</button>
						</div>
						<div>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Delete Table"
								:disabled="!editor.can().deleteTable()"
								@click="
									editor
										.chain()
										.focus()
										.deleteTable()
										.run()
								"
							>
								<IconTableRemove class="w-6 h-6" />
							</button>
						</div>
						<div>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Merge Cells"
								:disabled="!editor.can().mergeCells()"
								@click="
									editor
										.chain()
										.focus()
										.mergeCells()
										.run()
								"
							>
								<IconTableMergeCells class="w-6 h-6" />
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Split Cell"
								:disabled="!editor.can().splitCell()"
								@click="
									editor
										.chain()
										.focus()
										.splitCell()
										.run()
								"
							>
								<IconTableSplitCells class="w-6 h-6" />
							</button>
						</div>
						<div>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Toggle Header Column"
								:disabled="!editor.can().toggleHeaderColumn()"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeaderColumn()
										.run()
								"
							>
								Toggle Header Column
								<!-- <table-column-icon  /> -->
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Toggle Header Row"
								:disabled="!editor.can().toggleHeaderRow()"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeaderRow()
										.run()
								"
							>
								Toggle Header Row
								<!-- <table-row-icon  /> -->
							</button>
							<button
								class="relative inline-flex items-center px-2 py-1 border-r border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-blue-50 focus:z-10 focus:outline-none"
								title="Toggle Header Cell"
								:disabled="!editor.can().toggleHeaderCell()"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeaderCell()
										.run()
								"
							>
								Toggle Header Cell
								<!-- <rectangle-outline-icon  /> -->
							</button>
						</div>
					</div>
				</div>

				<div class="relative" :style="'min-height: ' + field.rows * 30 + 'px;'">
					<editor-content v-show="!rawHTML" :editor="editor" />
					<textarea
						v-show="rawHTML"
						v-model="inputVal"
						class="absolute top-0 left-0 w-full h-full border-none resize-none bg-gray-900 text-green-500 font-medium"
						:style="'min-height: ' + field.rows * 30 + 'px;'"
					/>
				</div>
			</div>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>

		<SlidePanel
			:slide-title="slideTitle"
			:slide-component="slideComponent"
			:slide-extra="slideExtra"
			:open="slideOpen"
			@closeSlide="closeSlide"
			@selectLink="selectLink"
			@selectMedia="selectMedia"
			@selectColor="selectColor"
		/>
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
} from '@headlessui/vue'
import { ChevronDownIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
// import History from "@tiptap/extension-history";
// import Bold from "@tiptap/extension-bold";
// import BulletList from "@tiptap/extension-bullet-list";
// import OrderedList from "@tiptap/extension-ordered-list";
// import ListItem from "@tiptap/extension-list-item";

import Underline from '@tiptap/extension-underline'
import Italic from '@tiptap/extension-italic'
import CustomTable from '@/extensions/CustomTable'
import TableRow from '@tiptap/extension-table-row'
import CustomTableCell from '@/extensions/CustomTableCell'
import CustomTableHeader from '@/extensions/CustomTableHeader'
import Link from '@tiptap/extension-link'
import CustomLink from '@/extensions/CustomLink'
import CustomImage from '@/extensions/CustomImage'
import TextStyle from '@/extensions/TextStyle'
// import TextStyle from '@tiptap/extension-text-style'
// import FontSize from '@/extensions/CustomFontSize'
import ColumnHeading from '@/extensions/ColumnHeading'
import Indent from '@/extensions/Indent'
import buttonLink from '@/extensions/ButtonLink'

import { ExclamationCircleIcon, PencilAltIcon, XIcon } from '@heroicons/vue/solid'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faBold,
	faListOl,
	faListUl,
	faLink,
	faImage,
	faRulerCombined,
	faTable,
	faPalette,
	faIndent,
	faOutdent,
	faHeading,
	faSquare,
	faItalic,
	faUnderline,
} from '@fortawesome/free-solid-svg-icons'

library.add(faBold)
library.add(faListOl)
library.add(faListUl)
library.add(faLink)
library.add(faImage)
library.add(faRulerCombined)
library.add(faTable)
library.add(faPalette)
library.add(faIndent)
library.add(faOutdent)
library.add(faHeading)
library.add(faSquare)
library.add(faItalic)
library.add(faUnderline)

import IconTableColumnPlusBefore from '@/components/Icon/Table/ColumnPlusBefore.vue'
import IconTableColumnPlusAfter from '@/components/Icon/Table/ColumnPlusAfter.vue'
import IconTableColumnRemove from '@/components/Icon/Table/ColumnRemove.vue'

import IconTableRowPlusBefore from '@/components/Icon/Table/RowPlusBefore.vue'
import IconTableRowPlusAfter from '@/components/Icon/Table/RowPlusAfter.vue'
import IconTableRowRemove from '@/components/Icon/Table/RowRemove.vue'

import IconTableRemove from '@/components/Icon/Table/Remove.vue'

import IconTableMergeCells from '@/components/Icon/Table/MergeCells.vue'
import IconTableSplitCells from '@/components/Icon/Table/SplitCells.vue'

// import TableColumnIcon from 'vue3-material-design-icons/TableColumn.vue'
// import TableRowIcon from 'vue3-material-design-icons/TableRow.vue'
// import RectangleOutlineIcon from 'vue3-material-design-icons/RectangleOutline.vue'
import IconButtonLink from '@/components/Icon/ButtonLink.vue'

export default {
	components: {
		ExclamationCircleIcon,
		EditorContent,
		'font-awesome-icon': FontAwesomeIcon,
		IconTableColumnPlusBefore,
		IconTableColumnPlusAfter,
		IconTableColumnRemove,
		IconTableRowPlusBefore,
		IconTableRowPlusAfter,
		IconTableRowRemove,
		IconTableRemove,
		IconTableMergeCells,
		IconTableSplitCells,
		// TableColumnIcon,
		// TableRowIcon,
		// RectangleOutlineIcon,
		IconButtonLink,
		PencilAltIcon,
		XIcon,
		Listbox,
		ListboxButton,
		ListboxOption,
		ListboxOptions,
		CheckIcon,
		SelectorIcon,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: Boolean,
	},
	emits: ['update:modelValue'],
	data() {
		return {
			init: true,
			baseUrl: '',
			slideOpen: false,
			slideTitle: '',
			slideComponent: '',
			slideExtra: null,
			editor: null,
			rawHTML: false,
			selection: {
				image: {
					width: 0,
					maxWidth: 0,
					height: 0,
					maxHeight: 0,
					aspect: 0,
					position: '',
					radius: '',
				},
			},
			fontSizes: [
				{ size: 'text-xs', name: '75%' },
				{ size: 'text-sm', name: '87%' },
				{ size: 'text-base', name: '100%' },
				{ size: 'text-lg', name: '112%' },
				{ size: 'text-xl', name: '125%' },
				{ size: 'text-2xl', name: '150%' },
			],
			fontSize: { size: 'text-base', name: '100%' },
			fontColors: [
				{ color: 'text-gray-900', name: 'Black' },
				{ color: 'text-gray-500', name: 'Gray' },
				{ color: 'text-sc-blue', name: 'Blue' },
				{ color: 'text-sc-green', name: 'Green' },
				{ color: 'text-sc-gold', name: 'Gold' },
				{ color: 'text-sc-light-blue', name: 'Light Blue' },
				{ color: 'text-sc-orange', name: 'Orange' },
				{ color: 'text-sc-purple', name: 'Purple' },
			],
			fontColor: { color: 'text-gray-900', name: 'Black' },
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('INPUT:', val)
				this.$emit('update:modelValue', val)
			},
		},
	},
	watch: {
		inputVal: function(val) {
			// console.log(val)
			// if (!val) {
			// 	console.log('run init again')
			// 	this.init = true
			// }
			this.init = true
			this.shouldInit()
		},

		fontColor: function(val) {
			// console.log('fontColorChanged', val, this.fontColor)

			this.editor
				.chain()
				.focus()
				.setTextStyle({ color: this.fontColor.color })
				.run()
		},

		fontSize: function(val) {
			// console.log('fontSizeChanged', val, this.fontSize)

			this.editor
				.chain()
				.focus()
				.setTextStyle({ size: this.fontSize.size })
				.run()
		},
	},
	beforeMount() {
		this.editor = new Editor({
			content: this.inputVal,
			extensions: [
				StarterKit.configure({
					paragraph: {
						HTMLAttributes: {
							class: 'sc-para',
						},
					},
					orderedList: {
						HTMLAttributes: {
							class: 'list-decimal pl-8',
						},
					},
					bulletList: {
						HTMLAttributes: {
							class: 'list-disc pl-8',
						},
					},
				}),
				Italic,
				Underline,
				CustomLink,
				// Link.configure({
				// 	HTMLAttributes: {
				// 		target: '_blank',
				// 		'aria-label': 'Opens in a new window',
				// 		rel: '',
				// 	},
				// }),
				CustomImage,
				CustomTable.configure({
					resizable: true,
				}),
				TableRow,
				CustomTableHeader,
				CustomTableCell,
				TextStyle,
				// FontSize,
				ColumnHeading,
				Indent,
				buttonLink,
			],
			editorProps: {
				attributes: {
					class: 'text-base mx-auto bg-white h-full px-3 py-2 focus:outline-none overflow-auto',
					style: 'min-height: ' + this.field.rows * 30 + 'px;',
				},
			},
			onUpdate: () => {
				this.inputVal = this.editor.getHTML()
			},
		})

		this.editor.on('selectionUpdate', ({ editor }) => {
			// console.log(editor.view.state)
			this.selectionUpdate(editor.view.state.selection)
		})
	},
	mounted() {},
	updated() {
		// console.log(this.inputVal)
	},
	beforeUnmount() {
		this.editor.off('selectionUpdate')
		this.editor.destroy()
	},
	methods: {
		shouldInit() {
			// console.log(html === this.inputVal)
			if (this.init) {
				let html = this.editor.getHTML()
				if (html !== this.inputVal) {
					this.init = false
					this.editor.commands.setContent(this.inputVal)
				}
			}
		},

		updateEditor() {
			// console.log(this.editor);
			this.editor.commands.setContent(this.inputVal)
			this.rawHTML = false
		},

		addTable() {
			if (!this.editor.isActive('table')) {
				this.editor
					.chain()
					.focus()
					.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
					.run()
			}
		},

		selectionUpdate(selection) {
			// console.log('selection', selection)

			if (selection.$anchor.nodeAfter != null) {
				let child = selection.$anchor.nodeAfter
				// console.log('child', child)

				if (child.marks.length) {
					child.marks.forEach(mark => {
						// console.log('mark', mark)
						if (mark.type.name == 'textStyle') {
							// console.log(mark.attrs)

							let color = mark.attrs.color
							if (typeof mark.attrs.color === 'object' && mark.attrs.color !== null) {
								color = mark.attrs.color.color
							}

							let size = mark.attrs.size
							if (typeof mark.attrs.size === 'object' && mark.attrs.size !== null) {
								size = mark.attrs.size.size
							}

							let sizeIndex = this.fontSizes.findIndex(x => x.size === size)
							let colorIndex = this.fontColors.findIndex(x => x.color === color)

							if (sizeIndex != -1) {
								this.fontSize = this.fontSizes[sizeIndex]
								// console.log('fontSize', this.fontSize)
							} else {
								this.fontSize = this.fontSizes[0]
							}
							if (colorIndex != -1) {
								this.fontColor = this.fontColors[colorIndex]
								// console.log('fontColor', this.fontColor)
							} else {
								this.fontColor = this.fontColors[2]
							}
						}
					})
				} else {
					this.setDefaultStyles()
				}
			} else {
				this.setDefaultStyles()
			}

			if (typeof selection.node !== 'undefined') {
				// console.log(selection.node)

				if (selection.node.type.name === 'image') {
					this.getImageAttrs(selection.node)
				}
			} else if (
				selection.$anchor.nodeAfter != null &&
				typeof selection.$anchor.nodeAfter !== 'undefined' &&
				selection.$anchor.nodeAfter.marks !== 'undefined' &&
				selection.$anchor.nodeAfter.marks.length > 0
			) {
				// console.log('nodeAfter')
				let linkIndex = selection.$anchor.nodeAfter.marks.findIndex(x => x.type.name === 'link')
				let buttonLinkIndex = selection.$anchor.nodeAfter.marks.findIndex(x => x.type.name === 'buttonLink')

				if (linkIndex != -1) {
					// console.log('link')
					this.getLinkAttrs(selection.$anchor.nodeAfter.marks[linkIndex])
				} else if (buttonLinkIndex != -1) {
					// console.log('buttonLink')
					this.getButtonLinkAttrs(selection.$anchor.nodeAfter.marks[buttonLinkIndex])
				}
			}
		},

		setDefaultStyles() {
			// console.log('DEFAULT STYLES')
			this.fontSize = this.fontSizes[2]
			this.fontColor = this.fontColors[0]
		},

		getImageAttrs(image) {
			this.selection.image = {
				'data-id': image.attrs['data-id'],
				src: image.attrs.src,
				alt: image.attrs.alt,
				width: image.attrs.width,
				maxWidth: image.attrs.width,
				height: image.attrs.height,
				maxHeight: image.attrs.height,
				aspect: image.attrs.width / image.attrs.height,
				radius: image.attrs['data-radius'],
				position: image.attrs['data-position'],
			}
		},

		getLinkAttrs(link) {
			this.selection.link = link.attrs
		},

		getButtonLinkAttrs(buttonLink) {
			let parts = buttonLink.attrs.onClick.match(/window\.open\('(.+)'\);/)
			// console.log('parts', parts)

			if (parts && parts.length == 2) {
				this.selection.buttonLink = {
					href: parts[1],
				}
			} else {
				let parts = buttonLink.attrs.onClick.split('=')
				// console.log('parts', parts)
				this.selection.buttonLink = {
					href: parts[1].replaceAll("'", ''),
				}
			}

			// console.log(this.selection.buttonLink)
		},

		editLink() {
			this.slideTitle = 'Select Link'
			this.slideComponent = 'MediaLinkSelect'
			this.slideOpen = true
		},

		editButtonLink() {
			this.slideTitle = 'Select Link'
			this.slideComponent = 'MediaLinkSelect'
			this.slideExtra = 'button'
			this.slideOpen = true
		},

		updateImage(dimension = false, position = false) {
			// console.log(this.selection.image);

			if (dimension) {
				if (dimension === 'width') {
					this.selection.image.height = Math.round(this.selection.image.width / this.selection.image.aspect)
				} else if (dimension === 'height') {
					this.selection.image.width = Math.round(this.selection.image.height * this.selection.image.aspect)
				}
			} else {
				if (
					parseInt(this.selection.image.width) > parseInt(this.selection.image.maxWidth) ||
					parseInt(this.selection.image.height) > parseInt(this.selection.image.maxHeight)
				) {
					this.selection.image.width = parseInt(this.selection.image.maxWidth)
					this.selection.image.height = parseInt(this.selection.image.maxHeight)
				}

				if (parseInt(this.selection.image.width) < 100) {
					this.selection.image.width = 100
					this.selection.image.height = Math.round(this.selection.image.width / this.selection.image.aspect)

					if (parseInt(this.selection.image.height) < 100) {
						this.selection.image.height = 100
						this.selection.image.width = Math.round(
							this.selection.image.height * this.selection.image.aspect
						)
					}
				} else if (parseInt(this.selection.image.height) < 100) {
					this.selection.image.height = 100
					this.selection.image.width = Math.round(this.selection.image.height * this.selection.image.aspect)

					if (parseInt(this.selection.image.width) < 100) {
						this.selection.image.width = 100
						this.selection.image.height = Math.round(
							this.selection.image.width / this.selection.image.aspect
						)
					}
				}

				this.editor
					.chain()
					.focus()
					.setImage({
						src: this.selection.image.src,
						alt: this.selection.image.alt,
						width: this.selection.image.width,
						height: this.selection.image.height,
						class: [this.selection.image.position, this.selection.image.radius].join(' '),
						'data-id': this.selection.image['data-id'],
						'data-position': this.selection.image.position,
						'data-radius': this.selection.image.radius,
					})
					.run()
			}
		},

		isSelectionEmpty() {
			const { view } = this.editor
			const { selection } = view.state
			return selection.empty
		},

		openSlide(component, extra = null) {
			const { node } = this.editor.state.selection

			this.slideExtra = extra
			if (component === 'MediaLinkSelect') {
				if (extra && extra == 'button') {
					// buttonLink
					if (!this.isSelectionEmpty()) {
						this.slideTitle = 'Select Link'
						this.slideComponent = 'MediaLinkSelect'
						this.slideOpen = true
					}
				} else {
					if (!this.isSelectionEmpty()) {
						this.slideTitle = 'Select Link'
						this.slideComponent = 'MediaLinkSelect'
						this.slideOpen = true
					}
				}
			} else if (!this.editor.isActive('image') && component === 'MediaImageSelect') {
				this.slideTitle = 'Select Image'
				this.slideComponent = 'MediaImageSelect'
				this.slideOpen = true
			} else if (component === 'MediaColorSelect') {
				this.slideTitle = 'Select Color'
				this.slideComponent = 'MediaColorSelect'
				this.slideOpen = true
			}
		},

		selectColor(color) {
			if (color === 'reset') {
				this.editor
					.chain()
					.unsetTextStyle()
					.focus()
					.run()
			} else {
				this.editor
					.chain()
					.setTextStyle({ color: 'text' + color })
					.focus()
					.run()
			}
		},

		closeSlide() {
			this.slideOpen = false
		},

		selectLink(link) {
			if (link.button) {
				let command =
					link.target == '_self' ? "location.href='" + link.href + "'" : "window.open('" + link.href + "');"
				this.editor
					.chain()
					.focus()
					.setButtonLink({
						onClick: command,
					})
					.run()

				this.selection.buttonLink = {
					href: link.href,
				}
			} else {
				this.editor
					.chain()
					.focus()
					.setLink(link)
					.run()

				this.selection.link = link
			}
		},

		selectMedia(image) {
			this.editor
				.chain()
				.focus()
				.setImage({
					src: image.url,
					alt: image.alt_text,
					width: image.width,
					height: image.height,
					'data-id': image.id,
					'data-position': image.position,
					'data-radius': image.radius,
				})
				.run()

			// this.editor.chain().focus().setLink(link).run();
		},

		fontColorBg(color) {
			return color.color.replace('text', 'bg')
		},
	},
}
</script>

<style lang="scss">
.ProseMirror {
	a {
		color: blue;
		text-decoration: underline;

		&:hover {
			cursor: pointer;
			color: #555;
		}
	}
}
</style>
